import React, { useState, useEffect, useCallback } from 'react';
import uuid from 'react-uuid';
import AlertService from '../../Services/AlertService';
import ApiService from '../../Services/ApiService';
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../Store/Actions/spinner';
import { useDispatch } from 'react-redux';
import ActionButton from '../../Components/Buttons/ActionButton';
import { Card, Modal } from "react-bootstrap"
import signInDecorSvg from "./../../assets/images/decors/sign-in-decor.svg"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MainService from '../../Services/MainService';
import ReactSelectOption from '../../Components/SelectOption/ReactSelectOption';
import { fielsLengths } from '../../Constants/MainKeys';

const buttonSpinnerId = uuid();
export default function CreateAccountForm() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referal = queryParams.get('referal');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAgree, setIsAgree] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);

  const [values, setValues] = useState({
    legalBusinessName: "",
    firstName: "",
    lastName: "",
    countryId: null,
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  })

  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
  const [isMismatchPasswords, setIsMismatchPasswords] = useState(false);

  useEffect(() => {
    referal && checkInvitation(referal);
  }, [referal])

  useEffect(() => {
    getCountries();

    return () => {
      setCountries([])
    }
  }, [])

  const checkInvitation = (referal) => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.checkInvitation(referal).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setValues((values) => ({
          ...values,
          email: data.email,
          legalBusinessName: data.legalBusinessName,
          agentId: data.agentId,
        }))
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractPageSpinner(spinnerId)
    })
  }

  const getCountries = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractPageSpinner(spinnerId)
    })
  }

  const onCheckBoxChange = (event, cb) => {
    cb(event.target.checked)
  }

  const onChange = (event, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value
    }));
    if (event.target.name === "password" || event.target.name === "confirmPassword") {
      setIsMismatchPasswords(false);
    }
    setIsInvalidSubmit(false);
  }

  const checkPhoneNumber = () => {
    const { phoneNumber } = values;
    // const phoneNumberRegex = /^(\+\d{1,3})?\d{9,15}$/;
    const phoneNumberRegex = /^\+?\d{1,3}[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

    if (!phoneNumber) {
      setIsInvalidSubmit(true);
      return false;
    }

    if (!phoneNumberRegex.test(phoneNumber)) {
      setIsInvalidPhoneNumber(true);
      return false;
    }
    setIsInvalidPhoneNumber(false);
  };

  const onCheckPassword = () => {
    if (values.password.trim() !== values.confirmPassword.trim()) {
      setIsMismatchPasswords(true);
    }
  };

  const onDateChange = (event, cb) => {
    cb(event.target.value);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      isInvalidPhoneNumber ||
      !values.firstName.trim().length ||
      !values.lastName.trim().length ||
      !values.email.trim().length ||
      !values.phoneNumber ||
      !values.password.trim().length ||
      !values.legalBusinessName.trim().length ||
      !values.confirmPassword.trim().length ||
      !values.countryId
    ) {
      setIsInvalidSubmit(true);
      return false;
    }
    values.password = window.btoa(values.password);
    values.confirmPassword = window.btoa(values.confirmPassword);
    setButtonSpinner(buttonSpinnerId);
    ApiService.createAccount(referal, values).then(response => {
      setIsSubmited(true)

    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId)
    })
  }

  const onSelectOptionChange = (item, fieldName) => {
    if (!item) { return false; }
    setValues(values => ({
      ...values,
      [fieldName]: item.value
    }))
    setIsInvalidSubmit(false);
  }

  const closeModal = () => {
    navigate("/")
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  return <section className="cx--cover-container">


    <Modal
      show={isSubmited}
      variant="primary"
      size="lg"
      className='create-prganization-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeModal}
    >
      <Modal.Body style={{ borderRadius: "10px" }}>
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex justify-content-center'>
            <img src='https://cdn-icons-png.freepik.com/512/7518/7518748.png' width={80} />
          </div>
          <h4 className='text-center'>Congratulations! </h4>
          <p className='text-center'>
            Your registration has been successful. We've sent a verification link to your email. Please check your inbox for verification.
          </p>
          <div className='d-flex justify-content-center'>
            <button
              className={`cx--btn cx--btn-primary px-5`}
              onClick={() => navigate(`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/login`)}
            >
              Sign In
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <img className="cx--cover-decor" src={signInDecorSvg} alt="signin-decor"></img>
    <div className="container">
      <div className="cx--cover-body-wrapper">
        <div className="cx--cover-form">
          <div className="row">
            <div className='col-12 col-md-8'>

              <h1>
                We look forward to working with you!
              </h1>
              <p className="cx--card-body cx--text-mute">
                Create an account with Cryllex to begin the onboarding process. A sales representative
                will contact you shortly at the email address provided.
              </p>
              <p className="cx--cover-sub-title">Already have an account?
                {/* &nbsp;<Link to="/sign-in"> Sign in</Link> */}
                &nbsp;<a href={`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/login`}> Sign in</a>
              </p>
            </div>
          </div>
          <form className='row mt-3' onSubmit={onSubmit}>

            <div className='col-12'>
              {/* left */}
              <div className='row'>

                <div className='col-lg-3'>
                  <div className="cx--form-control">
                    <input
                      type="text"
                      name="name"
                      placeholder='Ex. John'
                      value={values.firstName}
                      className={` ${isInvalidSubmit && !values.firstName.trim().length ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "firstName", fielsLengths.length_120)}
                    />
                    <span>First Name*</span>
                  </div>
                </div>

                <div className='col-lg-3'>
                  <div className="cx--form-control">
                    <input
                      type="text"
                      name="name"
                      placeholder='Ex. Smith'
                      value={values.lastName}
                      className={` ${isInvalidSubmit && !values.lastName.trim().length ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "lastName", fielsLengths.length_120)}
                    />
                    <span>Last Name*</span>
                  </div>
                </div>

                <div className='col-lg-3'>
                  <div className="cx--form-control">
                    <input
                      type="text"
                      name="legalBusinessName"
                      placeholder='Ex. Horizon Industries'
                      readOnly={referal ? true : false}
                      value={values.legalBusinessName}
                      className={` ${isInvalidSubmit && !values.legalBusinessName.trim().length ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "legalBusinessName", fielsLengths.length_120)}
                    />
                    <span>Company Name*</span>
                  </div>
                </div>

                <div className='col-lg-3'>
                  <div className="cx--form-control">
                    <input
                      type="email"
                      name="email"
                      placeholder='Ex. example@gmail.com'
                      autoComplete='new-password'
                      readOnly={referal ? true : false}
                      value={values.email}
                      className={`text-initial ${isInvalidSubmit && !values.email.trim().length ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "email", fielsLengths.length_120)}
                    />
                    <span className='text-initial'>E-mail*</span>
                  </div>
                </div>

                <div className='col-lg-3'>
                  <div className="cx--form-control">
                    <ReactSelectOption
                      value={values.countryId}
                      isInvalidSubmit={isInvalidSubmit}
                      selectedValue={(() => {
                        const selectedValue = { ...countries.find(data => data.id === values.countryId) };
                        if (Object.keys(selectedValue).length) {
                          selectedValue.label = selectedValue.name;
                          selectedValue.value = selectedValue.id;
                          return selectedValue;
                        } else {
                          return { label: "Country*", value: null }
                        }
                      })()}
                      className="custom-select "
                      items={countries.map(data => ({ label: data.name, value: data.id }))}
                      onChange={(item) => onSelectOptionChange(item, "countryId")}
                    />
                  </div>
                </div>


                <div className='col-lg-3'>
                  <div className="cx--form-control">
                    <input
                      id="phoneNumber"
                      type="tel"
                      name="phoneNumber"
                      placeholder='Ex. +1 555 555 1234'
                      value={values.phoneNumber}
                      className={`${(isInvalidSubmit && (!values.phoneNumber.trim() || values.phoneNumber.trim().length <= 1)) || isInvalidPhoneNumber ? "cx--danger-border" : ""}`}
                      onChange={event => {
                        setIsInvalidPhoneNumber(false);
                        if (event.target.value.length) {
                          if ((event.target.value.length < fielsLengths.length_32 && typeof +event.target.value === "number" && Number(event.target.value) >= 0) || event.target.value === "+") {
                            setValues((values) => ({
                              ...values,
                              phoneNumber: event.target.value,
                            }));
                          } else { return; }
                        } else {
                          setValues((values) => ({
                            ...values,
                            phoneNumber: "",
                          }));
                        }
                      }}
                      onBlur={(event) => {
                        checkPhoneNumber(event.target.value);
                      }}
                    />
                    <span>Phone Number*</span>
                  </div>
                </div>

                <div className='col-lg-3'>
                  <div className="cx--form-control">
                    <input
                      type="password"
                      name="password"
                      placeholder='Password*'
                      autoComplete='new-password'
                      value={values.password}
                      className={`text-initial ${(isInvalidSubmit && !values.password.trim().length) || isMismatchPasswords ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "password", fielsLengths.length_120)}
                      onBlur={onCheckPassword}
                    />
                    <span>Password*</span>
                  </div>
                </div>

                <div className='col-lg-3'>
                  <div className="cx--form-control">
                    <input
                      type="password"
                      name="confirmPassword"
                      placeholder='Confirm password'
                      autoComplete='new-password*'
                      value={values.confirmPassword}
                      className={`text-initial ${(isInvalidSubmit && !values.confirmPassword.trim().length) || isMismatchPasswords ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "confirmPassword", fielsLengths.length_120)}
                      onBlur={onCheckPassword}
                    />
                    <span>Confirm password*</span>
                  </div>
                </div>

              </div>
            </div>

            <div className='col-12'>
              <div className="d-flex align-items-baseline">
                <input
                  id='isAgree'
                  type="checkbox"
                  name="isAgree"
                  className='mx-2 d-block'
                  checked={isAgree}
                  onChange={event => onCheckBoxChange(event, setIsAgree)}
                />
                <label htmlFor='isAgree' className='mb-1 cursor-pointer'>
                  I agree to the&nbsp;
                  <Link to="#">Terms of Service</Link>
                  &nbsp;and&nbsp;
                  <Link to="/privacy-policy" target='_blank'>Privacy Policy.</Link>
                </label>
              </div>

              <ActionButton
                name="Submit"
                type="submit"
                disabled={!isAgree || isInvalidPhoneNumber || isMismatchPasswords}
                className={`cx--btn cx--btn-primary mt-3 px-5 ${!isAgree ? "disabled" : ""}`}
                spinnerId={buttonSpinnerId}
              />
              {
                isMismatchPasswords ?
                  <small className='text-danger mt-2 d-block'>Password mismatch</small>
                  : null
              }
            </div>
            {/* cx--danger-border */}
          </form>
        </div>
      </div>
    </div>
  </section>
}
