import React, { useState, useEffect, useCallback } from 'react';
import uuid from 'react-uuid';
import AlertService from '../../Services/AlertService';
import ApiService from '../../Services/ApiService';
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../Store/Actions/spinner';
import { useDispatch } from 'react-redux';
import ActionButton from '../../Components/Buttons/ActionButton';
import { Card, Modal } from "react-bootstrap"
import signInDecorSvg from "./../../assets/images/decors/sign-in-decor.svg"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MainService from '../../Services/MainService';
import ReactSelectOption from '../../Components/SelectOption/ReactSelectOption';
import { fielsLengths } from '../../Constants/MainKeys';

const buttonSpinnerId = uuid();
export default function BecomeAnAgent() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAgree, setIsAgree] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    legalBusinessName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  })

  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
  const [isMismatchPasswords, setIsMismatchPasswords] = useState(false);

  const onCheckBoxChange = (event, cb) => {
    cb(event.target.checked)
  }

  const onChange = (event, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value
    }));
    if (event.target.name === "password" || event.target.name === "confirmPassword") {
      setIsMismatchPasswords(false);
    }
    setIsInvalidSubmit(false);
  }

  const onCheckPassword = () => {
    if (values.password.trim() !== values.confirmPassword.trim()) {
      setIsMismatchPasswords(true);
    }
  };

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (
      isInvalidPhoneNumber ||
      !values.email.trim().length ||
      !values.legalBusinessName.trim().length ||
      !values.password.trim().length ||
      !values.confirmPassword.trim().length
    ) {
      setIsInvalidSubmit(true);
      return false;
    }
    values.password = btoa(values.password);
    values.confirmPassword = btoa(values.confirmPassword);
    setButtonSpinner(buttonSpinnerId);
    ApiService.becomeAnAgent(values).then(response => {
      setIsSubmited(true)
    }).catch(error => { AlertService.alert("error", error) }).finally(() => {
      extractButtonSpinner(buttonSpinnerId)
    })
  }

  const checkPhoneNumber = (phoneNumber) => {
    if ((phoneNumber && (phoneNumber.length > fielsLengths.length_32 || phoneNumber.length < fielsLengths.length_6)) || !phoneNumber || phoneNumber.includes(".") || phoneNumber.includes("e")) {
      setIsInvalidPhoneNumber(true);
      return false;
    } else {
      setIsInvalidPhoneNumber(false);
      return false;
    }
  }

  const closeModal = () => {
    navigate("/")
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  return <section className="cx--cover-container">


    <Modal
      show={isSubmited}
      variant="primary"
      size="lg"
      className='create-prganization-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeModal}
    >
      <Modal.Body style={{ borderRadius: "10px" }}>
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex justify-content-center'>
            <img src='https://cdn-icons-png.freepik.com/512/7518/7518748.png' width={80} />
          </div>
          <h4 className='text-center'>Congratulations! </h4>
          <p className='text-center'>
            Your registration has been successful. We've sent a verification link to your email. Please check your inbox for verification.
          </p>
          <div className='d-flex justify-content-center'>
            <button
              className={`cx--btn cx--btn-primary px-5`}
              onClick={() => navigate("/")}
            >
              Home
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <img className="cx--cover-decor" src={signInDecorSvg} alt="signin-decor"></img>
    <div className="container">
      <div className="cx--cover-body-wrapper">
        <div className="cx--cover-form">
          <div className="row">
            <div className='col-12 col-md-8'>

              <h1>
                We look forward to working with you!
              </h1>
              <p className="cx--card-body cx--text-mute">
                Create an account <b>agent</b> with Cryllex to begin the onboarding process. A sales representative
                will contact you shortly at the email address provided.
              </p>
              <p className="cx--cover-sub-title">Already have an account?
                {/* &nbsp;<Link to="/sign-in"> Sign in</Link> */}
                &nbsp;<a href={`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/login`}> Sign in</a>
              </p>
            </div>
          </div>
          <form className='row mt-3' onSubmit={onSubmit}>

            <div className='col-12'>
              {/* left */}
              <div className='row'>

                <div className='col-lg-4'>
                  <div className="cx--form-control">
                    <input
                      type="text"
                      name="firstName"
                      placeholder='First Name'
                      value={values.firstName}
                      className={`text-initial`}
                      onChange={event => onChange(event, "firstName", fielsLengths.length_120)}
                    />
                    <span className='text-initial'>First Name</span>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className="cx--form-control">
                    <input
                      type="text"
                      name="lastName"
                      placeholder='Last Name'
                      value={values.lastName}
                      className={`text-initial`}
                      onChange={event => onChange(event, "lastName", fielsLengths.length_120)}
                    />
                    <span className='text-initial'>Last Name</span>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className="cx--form-control">
                    <input
                      type="email"
                      name="email"
                      placeholder='E-mail*'
                      autoComplete='new-password'
                      value={values.email}
                      className={`text-initial ${isInvalidSubmit && !values.email.trim().length ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "email", fielsLengths.length_120)}
                    />
                    <span className='text-initial'>E-mail*</span>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className="cx--form-control">
                    <input
                      id="phoneNumber"
                      type="tel"
                      name="phoneNumber"
                      placeholder='Phone Number*'
                      value={values.phoneNumber}
                      className={`${(isInvalidSubmit && (!values.phoneNumber.trim() || values.phoneNumber.trim().length <= 1)) || isInvalidPhoneNumber ? "cx--danger-border" : ""}`}
                      onChange={event => {
                        setIsInvalidPhoneNumber(false);
                        if (event.target.value.length) {
                          if ((event.target.value.length < fielsLengths.length_32 && typeof +event.target.value === "number" && Number(event.target.value) >= 0) || event.target.value === "+") {
                            setValues((values) => ({
                              ...values,
                              phoneNumber: event.target.value,
                            }));
                          } else { return; }
                        } else {
                          setValues((values) => ({
                            ...values,
                            phoneNumber: "",
                          }));
                        }
                      }}
                      onBlur={(event) => {
                        checkPhoneNumber(event.target.value);
                      }}
                    />
                    <span className='text-initial'>Phone Number*</span>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className="cx--form-control">
                    <input
                      type="text"
                      name="legalBusinessName"
                      placeholder='Legal Business Name*'
                      value={values.legalBusinessName}
                      className={`text-initial ${isInvalidSubmit && !values.legalBusinessName.trim().length ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "legalBusinessName", fielsLengths.length_120)}
                    />
                    <span className='text-initial'>Legal Business Name*</span>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className="cx--form-control">
                    <input
                      type="password"
                      name="password"
                      placeholder='Password*'
                      autoComplete='new-password'
                      value={values.password}
                      className={`text-initial ${(isInvalidSubmit && !values.password.trim().length) || isMismatchPasswords ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "password", fielsLengths.length_120)}
                      onBlur={onCheckPassword}
                    />
                    <span>Password*</span>
                  </div>
                </div>

                <div className='col-lg-4'>
                  <div className="cx--form-control">
                    <input
                      type="password"
                      name="confirmPassword"
                      placeholder='Confirm password'
                      autoComplete='new-password*'
                      value={values.confirmPassword}
                      className={`text-initial ${(isInvalidSubmit && !values.confirmPassword.trim().length) || isMismatchPasswords ? "cx--danger-border" : ""}`}
                      onChange={event => onChange(event, "confirmPassword", fielsLengths.length_120)}
                      onBlur={onCheckPassword}
                    />
                    <span>Confirm password*</span>
                  </div>
                </div>

              </div>
            </div>

            <div className='col-12'>
              <div className="d-flex align-items-baseline">
                <input
                  id='isAgree'
                  type="checkbox"
                  name="isAgree"
                  className='mx-2 d-block'
                  checked={isAgree}
                  onChange={event => onCheckBoxChange(event, setIsAgree)}
                />
                <label htmlFor='isAgree' className='mb-1 cursor-pointer'>
                  I agree to the&nbsp;
                  <Link to="#">Terms of Service</Link>
                  &nbsp;and&nbsp;
                  <Link to="/privacy-policy" target='_blank'>Privacy Policy.</Link>
                </label>
              </div>

              <ActionButton
                name="Submit"
                type="submit"
                disabled={!isAgree || isInvalidPhoneNumber || isMismatchPasswords}
                className={`cx--btn cx--btn-primary mt-3 px-5 ${!isAgree ? "disabled" : ""}`}
                spinnerId={buttonSpinnerId}
                onClick={onSubmit}
              />
              {
                isMismatchPasswords ?
                  <small className='text-danger mt-2 d-block'>Password mismatch</small>
                  : null
              }
            </div>
            {/* cx--danger-border */}
          </form>
        </div>
      </div>
    </div>
  </section>
}
