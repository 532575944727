import React from 'react'
import aboutUsCoverSvg from "./../../assets/images/qr-phone.svg"
import coverSvg from "./../../assets/images/decors/home-cover-decor.svg"
import speedSvg from "./../../assets/icons/w35/speed.svg"
import protectionSvg from "./../../assets/icons/h70/protection.svg"
import costSvg from "./../../assets/icons/w35/cost.svg"
import statisticSvg from "./../../assets/images/statistic.svg"
import { useNavigate } from 'react-router'
import ContactUsComponent from '../ContactUs/ContactUsComponent'
import ContactLaptopSvg from "./../../assets/images/contact-laptop.svg"
import decor4x4Svg from "./../../assets/images/decors/decor-4x4.svg";
import decor4x4BlueSvg from "./../../assets/images/decors/decor-4x4-blue.svg";

export default function AboutUs() {

    const navigate = useNavigate();

    return <React.Fragment>
        <section className="cx--cover-container">
            <img className="cx--cover-decor" src={coverSvg} alt="cover" />
            <div className="container">
                <div className="cx--cover-body-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <h1 className="cx--cover-title">
                                <span className="cx--secondary-color">Future</span> proof<br />
                                your <span className="cx--secondary-color">business</span>
                            </h1>
                            <p className="cx--cover-text mt-3">
                                Creating innovative solutions to elevate conventional businesses,
                                preparing them to interact with the endless possibilities of the
                                impending financial future.
                            </p>
                        </div>
                        <div className="col-lg-5">
                            <div className="cx--cover-image">
                                <img src={aboutUsCoverSvg} alt="about-us-cover" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="cx--cover-container">
            <div className="cx--dark-background">
                <div className="container">
                    <div className="cx--cover-bottom-container cx--sm-section mt-5 d-flex justify-content-center">
                        <div className="cx--cover-bottom-title-wrapper">
                            <div className="cx--cover-bottom-title d-flex flex-wrap">
                                <h5>
                                    The financial landscape is rapidly evolving,<br />
                                    and <span className='cx--secondary-color'>we keep you up to speed.</span>
                                </h5>
                                <div className='cx--vertical-line d-none d-md-block mx-5' />
                                <div className='d-block d-md-none my-5' />
                                <p className='cx--text-mute'>
                                    Cryllex is building enhanced financial applications to
                                    <br /> help businesses optimize their payment process flows.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="cx--section position-relative">
            <img className="cx--section-decoration cx--section-decoration-left" src={decor4x4BlueSvg} alt="decoration" />
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h2 className="cx--section-title cx--section-dark-title">
                            Our <span className="cx--primary-color">Mission</span>
                        </h2>
                        <p>
                            <br />
                            Weaving the web of a secure, accessible and traversable financial future
                            without borders, one innovative solution at a time.
                            <br />
                            <br />
                            <small>At Cryllex, we strive to harness blockchain technology to bring the luxuries afforded by our
                                financial system to people across the globe. Our products are the perfect synthesis of innovation
                                and practicality, addressing the practical and economical shortcomings of
                                existing processes and revolutionizing them with innovative applications.</small>
                        </p>

                        <button
                            className="cx--btn cx--btn-primary cx--text-transform-initial mt-3"
                            type="button"
                            onClick={() => navigate("/solutions")}
                        >
                            Our Solutions
                        </button>
                    </div>
                    <div className="col-md-5 mt-2 mt-md-0">
                        <div className="cx--section-image-wrapper">
                            <img src={statisticSvg} alt="statistic" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="cx--section mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="cx--section-title-wrapper">
                            <h2 className="cx--section-title cx--section-dark-title">
                                Core Values
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-4 my-2 position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={protectionSvg} alt="protection" />
                                </div>
                                <h5 className="cx--card-title">Trust and Transparency</h5>
                            </div>
                            <p className="cx--card-body">
                                Transparency is key to maintaining unwavering trust. Cryllex is committed to disclosing figures, documentation and information that are central to operations and aims to remain on par with those who rely and interact closely with us.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 my-2 position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={speedSvg} alt="speed" />
                                </div>
                                <h5 className="cx--card-title">Safety and Security</h5>
                            </div>
                            <p className="cx--card-body">
                                Innovation is our priority, but never at the expense of client security. As a commitment to those within our ecosystem, we have partnered with some of the biggest industry players to maintain adequate reserve requirements and up-to-date white and black address lists.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 my-2 position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={costSvg} alt="cost" />
                                </div>
                                <h5 className="cx--card-title">Accelerated, Affordable
                                    and Accessible</h5>
                            </div>
                            <p className="cx--card-body">
                                Driving a global initiative, we strive to deliver
                                accelerated, affordable and accessible solutions
                                to all. For those in emerging markets or developed
                                countries, those with registered bank accounts
                                and those who are bankless, our products remain
                                at the edge of your fingertips, one simple device
                                and internet connection away.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="cx--cover-container">
            <img className="cx--section-decoration cx--section-decoration-left" src={decor4x4Svg} alt="decoration" />
            <div className="cx--dark-background">
                <div className="container">
                    <div className="cx--cover-bottom-container cx--sm-section mt-5">
                        <div className="cx--cover-bottom-title-wrapper">
                            <div className='row justify-content-between'>
                                <div className='col-12 col-md-6 mt-2'>
                                    <div className="cx--cover-bottom-title">
                                        <h2>
                                            <span className='cx--secondary-color'>Explore</span> a newfound versatility
                                            <br />
                                            to your <span className='cx--secondary-color'>financial options</span>
                                        </h2>
                                        <p className='text-muted'>
                                            Ready to elevate your business?
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-4 mt-2'>
                                    <div className='cx--cover-bottom-title'>
                                        <p className='text-muted'>
                                            This is your starting point, we handle
                                            the rest of your journey.
                                        </p>
                                        <a
                                            href='mailto: sales@cryllex.com'
                                            className="cx--btn cx--btn-secondary-outline py-2 mt-3 text-decoration-none"
                                            type="button"
                                        >
                                            Get started
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="cx--section">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 mt-5">
                        <h2 className="cx--section-title cx--section-dark-title cx--text-transform-initial">
                            Have a question for us to
                            answer?
                        </h2>
                        <p className="cx--card-body cx--text-mute">
                            Send us an email detailing your query and we will get back to
                            you shortly!
                        </p>
                        <button
                            className="cx--btn cx--btn-primary mt-3"
                            type="submit"
                            onClick={() => navigate("/contact-us")}
                        >
                            Let’s Connect
                        </button>
                    </div>
                    <div className="col-lg-6 text-center d-lg-block d-none">
                        <img className="cx--aside-image" src={ContactLaptopSvg} alt="contact-us" />
                    </div>
                </div>
            </div>
        </section>

    </React.Fragment>
}
